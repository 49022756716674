import { ITKElement } from "@monorepo/inventory/src/views/TKListView/types/tkElement";
import moment from "moment";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKListView/fields";

export const convertApiItemToUi = (data: any): any => {
  return {
    ...data,
    [fields.TK_LIST_STATUS]: data.status,
    [fields.TK_LIST_OIK_ID]: data?.tkOikGuid,
    [fields.TK_LIST_OIK_CODE]: data?.oik?.code,
    [fields.TK_LIST_OIK_NAME]: data?.oik?.name,
    [fields.TK_SOURCE_TYPE]: data?.sourceType ? (data?.sourceType === "MEDO" ? "МЭДО" : "API") : "",
    [fields.TK_LIST_CREATED_DATE]: data?.creationDate ? moment(data.creationDate).format("YYYY-MM-DD HH:mm") : "",
    [fields.TK_LIST_DELIVERY_DATE]: data?.receivedAt
      ? moment(data[fields.TK_LIST_DELIVERY_DATE as keyof ITKElement] as string, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD HH:mm")
      : "",
    [fields.TK_HAS_MRD]: data?.tkContainsMrd ? "Да" : "Нет",
    mrdPowerAttorneyNumber: data.mrdDto?.powerAttorneyNumber || "",
    mrdCreationDate: data.mrdDto?.creationDate ? moment(data.mrdDto.creationDate).format("YYYY-MM-DD HH:mm") : "",
    mrdExpiredAt: data.mrdDto?.expiredAt ? moment(data.mrdDto.expiredAt).format("YYYY-MM-DD HH:mm") : "",
    mrdTrusteeCompanyName: data.mrdDto?.trusteeCompanyName || "",
    mrdTrusteeInn: data.mrdDto?.trusteeInn || "",
    mrdTrusteeOgrn: data.mrdDto?.trusteeOgrn || "",
    mrdTrusteeFio: data.mrdDto?.trusteeFio || "",
    mrdRepresentativeFio: data.mrdDto?.representativeFio || "",
    mrdPermissions: data.mrdDto?.permissionText || "",
  };
};
