import { fields } from "@monorepo/utils/src/variables/projectsData/TKListView/fields";

export const filtersElements = [
  {
    value: fields.TK_LIST_TYPE_FILTER,
    title: "Тип ТК",
    tooltip: "Выпадающий список",
  },
  {
    value: fields.TK_LIST_STATUS,
    title: "Статус ТК",
    tooltip: "Выпадающий список",
  },
  {
    value: fields.TK_LIST_OIK_ID,
    title: "Идентификатор ТК ОИК",
    tooltip: "Ввод вручную",
  },
  {
    value: fields.TK_LIST_GUID,
    title: "Уникальный идентификатор ТК ЦХЭД",
    tooltip: "Ввод вручную",
  },
  {
    value: fields.TK_LIST_ID,
    title: "Идентификатор ТК ЦХЭД",
    tooltip: "Ввод вручную",
  },
  {
    value: fields.TK_LIST_MEDO_GUID,
    title: "Уникальный идентификатор ТК МЭДО",
    tooltip: "Ввод вручную",
  },
  {
    value: fields.TK_LIST_OIK_NAME,
    title: "Название ОИК",
    tooltip: "Предиктивный ввод",
  },
  {
    value: fields.TK_LIST_OIK_CODE,
    title: "Код ОИК",
    tooltip: "Предиктивный ввод",
  },
  {
    value: fields.TK_LIST_CREATED_DATE,
    title: "Дата создания ТК в ОИК",
    tooltip: "Выбор даты в окне календаря, после нажатия на поле фильтра",
  },
  {
    value: fields.TK_LIST_DELIVERY_DATE,
    title: "Дата поступления ТК в ЦХЭД",
    tooltip: "Выбор даты в окне календаря, после нажатия на поле фильтра",
  },
  {
    value: fields.TK_SOURCE_TYPE,
    title: "Канал поступления",
    tooltip: "Канал поступления: МЭДО, API",
  },
  {
    value: fields.FILTER_HAS_MRD,
    title: "Наличие МЧД",
    tooltip: "Наличие МЧД: да, нет",
  },
];
