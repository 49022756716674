export const mrdSelectItems = [
  {
    bool: true,
    value: "Да",
    title: "Да",
  },
  {
    bool: false,
    value: "Нет",
    title: "Нет",
  },
];
