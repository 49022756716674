import { Module } from "vuex";
import { IRootState } from "@monorepo/inventory/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/inventory/src/views/TKListView/utils/convertFiltersToApi";
import { ITKElement } from "@monorepo/inventory/src/views/TKListView/types/tkElement";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { TK_STATUS } from "@monorepo/inventory/src/views/TKListView/types/tkStatus";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";

interface ITableState {
  filters: ITableFiltersObject;
  libraries: {
    statuses: Record<string, string>[];
    types: Record<string, string>[];
  };
  searchTemplates: ISearchTemplate[];
  cells: string[];
  section?: Sections;
  sectionCells: ISection;
  totalLength: number;
  data: ITKElement[];
  isOpenFilters: boolean;
  autorefresh: boolean;
  infiniteId: string;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  fieldFilters: {},
  initMessagesLength: 50,
  isSelectAll: false,
  selectedIds: {},
  openedId: null,
  sort: {},
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_TK_LIST_CSV;
    case "XLSX":
      return QUERY_PATH.GET_TK_LIST_XLS;
    default:
      return QUERY_PATH.GET_TK_LIST_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    libraries: {
      statuses: [],
      types: [],
    },
    cells: [],
    section: Sections.TK_LIST,
    sectionCells: {} as ISection,
    searchTemplates: [],
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    totalLength: 0,
    isOpenFilters: false,
    autorefresh: false,
    data: [],
    isTableLoading: false,
    isLoadingToggleFilters: false,
    isLoadingChangeAutorefresh: false,
  }),
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    addStatuses(state: ITableState, payload: { code: TK_STATUS; title: string }[]) {
      state.libraries.statuses = (payload || []).map((item) => ({ value: item.code, title: item.title }));
    },
    addTypes(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.types = (payload || []).map((item) => ({ value: item.code, title: item.name }));
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addData(
      state: ITableState,
      payload: {
        data: ITKElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getTkElement(info, id: string) {
      const { data } = await getQuery<ITKElement[]>(`${QUERY_PATH.GET_TK_ELEMENT_CARD}/${id}`);
      return data;
    },
    async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      if (payload !== "PDF") {
        dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
          root: true,
        });
      } else {
        commit("app/setIsExportFileLoading", true, {
          root: true,
        });
        const { data } = await getQuery<void>(QUERY_PATH.GET_TK_LIST_PDF_ASYNC, params);
        if (!data) {
          commit("app/setIsExportFileLoading", false, {
            root: true,
          });
        }
      }
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<ITKElement[]>(QUERY_PATH.GET_TK_LIST_ELEMENTS, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<ITKElement[]>(QUERY_PATH.GET_TK_LIST_ELEMENTS, { ...params, offset: 0 });

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getStatuses({ commit }) {
      const { data } = await getQuery<ITKElement[]>(QUERY_PATH.GET_TK_STATUSES);
      if (data !== null) {
        commit("addStatuses", data);
      }
    },
    async getTKTypes({ commit }) {
      const { data } = await getQuery<string[]>(QUERY_PATH.GET_TK_TYPES);
      if (data !== null) {
        commit("addTypes", data);
      }
    },
  },
  getters: {
    ...baseGetters,
    statuses(state: ITableState) {
      return state.libraries.statuses;
    },
    types(state: ITableState) {
      return state.libraries.types;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
