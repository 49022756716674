














































































































































import { defineComponent } from "@vue/composition-api";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { ITkCardElement } from "@monorepo/utils/src/types/tkCardElement";
import moment from "moment";
import {
  baseModalElements,
  oikElements,
  medoElements,
  akElements,
  signatureElements,
  fullModalElements as modalElements,
  modalFields,
  mrpElements,
} from "@monorepo/utils/src/variables/projectsData/BaseTKInfoModal/modalElements";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { mapActions, mapGetters } from "vuex";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import VueRouter, { Route } from "vue-router";
import { Type } from "@monorepo/monitoring/src/views/TKLogView/constants/types";
import { getFileSize } from "@monorepo/utils/src/utils/getFileSize";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";

type element = Partial<
  ITkCardElement & {
    receivedAt: string;
    totalSize: string;
    sourceTkFsFileGuid?: string;
    tkExpirationDateAK: string;
    documentId: string;
    oikCode: string;
    oikName: string;
    akId: string;
    statusMessage: string;
    lastSigningDateAK: string;
    expirationDateAK: string;
    akResponseGuid: string;
    responseContainerGuid: string;
    mrdPowerAttorneyNumber: string;
    mrdCreationDate: string;
    mrdExpiredAt: string;
    mrdTrusteeCompanyName: string;
    mrdTrusteeInn: string;
    mrdTrusteeOgrn: string;
    mrdTrusteeFio: string;
    mrdRepresentativeFio: string;
    mrdPermissions: string;
    mrdStatus: string;
    mrdEpStatus: string;
    mrdMetaStatus: string;
  }
>;
export default defineComponent({
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    ExportBtn,
    CardModalChapter,
  },
  data(): {
    element: element;
    modalFields: typeof modalFields;
    modalElements: IModalElementType[];
    baseModalElements: IModalElementType[];
    oikElements: IModalElementType[];
    medoElements: IModalElementType[];
    akElements: IModalElementType[];
    signatureElements: IModalElementType[];
    mrpElements: IModalElementType[];
    openedPanels: number[];
    section: Sections;
    cardMode: typeof CardMode;
    isLoading: boolean;
  } {
    return {
      element: {},
      modalFields,
      modalElements,
      baseModalElements,
      oikElements,
      medoElements,
      akElements,
      signatureElements,
      mrpElements,
      section: Sections.TK_LIST,
      cardMode: CardMode,
      openedPanels: [0],
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getTkElement: (val: string) => Promise<ITkCardElement>;
          formSerialNumber: (text: string) => string;
          formDate: (text: string) => string;
          element: element;
          isLoading: boolean;
          isShowAnimation: boolean;
          $route: Route;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getTkElement(value)
            .then((data: ITkCardElement) => {
              this.element = {
                ...(data || {}),
                tkFileExist: data?.tkFileExist || false,
                statusMessage: data?.status ?? "",
                receivedAt: data?.receivedAt ? moment(data.receivedAt).format("YYYY-MM-DD HH:mm") : "",
                totalSize: getFileSize(+data?.totalSize ?? 0),
                volume: getFileSize(+(data?.volume || 0)),
                oikCode: data?.oik?.code ?? "",
                oikName: data?.oik?.name ?? "",
                tkExpirationDateAK: data?.akDigitalSignature?.expiredAt ? moment(data.akDigitalSignature.expiredAt).format("YYYY-MM-DD HH:mm") : "",
                transportDate: data?.transportDate ? moment(data.transportDate).format("YYYY-MM-DD HH:mm") : "",
                hduoId: data?.archiveContainer?.guid,
                hduoGuid: data?.archiveContainer?.hduoGuid,
                akStatus: data?.archiveContainer?.status,
                akId: data?.archiveContainer?.id,
                typeName: data?.type?.name || "",
                typeCode: data?.type?.code || "",
                sourceType: data?.sourceType ? (data?.sourceType === "MEDO" ? "МЭДО" : "API") : "",
                commonName: data?.digitalSignature?.commonName || "",
                organizationName: data?.digitalSignature?.organizationName || "",
                serNum: this.formSerialNumber(data?.digitalSignature?.serNum),
                expiredAt: this.formDate(data?.digitalSignature?.expiredAt),
                signTime: this.formDate(data?.digitalSignature?.signTime),
                timeStamp: this.formDate(data?.digitalSignature?.timeStamp),
                tsSerNum: this.formSerialNumber(data?.digitalSignature?.tsSerNum),
                mrdPowerAttorneyNumber: data.mrdDto?.powerAttorneyNumber || "",
                mrdCreationDate: data.mrdDto?.creationDate ? moment(data.mrdDto.creationDate).format("YYYY-MM-DD HH:mm") : "",
                mrdExpiredAt: data.mrdDto?.expiredAt ? moment(data.mrdDto.expiredAt).format("YYYY-MM-DD HH:mm") : "",
                mrdTrusteeCompanyName: data.mrdDto?.trusteeCompanyName || "",
                mrdTrusteeInn: data.mrdDto?.trusteeInn || "",
                mrdTrusteeOgrn: data.mrdDto?.trusteeOgrn || "",
                mrdTrusteeFio: data.mrdDto?.trusteeFio || "",
                mrdRepresentativeFio: data.mrdDto?.representativeFio || "",
                mrdPermissions: data.mrdDto?.permissionText || "",
                mrdStatus: data.mrdDto?.status?.title || "",
                mrdEpStatus: data.mrdDto?.validateSignStatus?.title || "",
                mrdMetaStatus: data.mrdDto?.validateMetadataStatus?.title || "",
              };

              if (data?.archiveContainer?.creationDate) {
                this.element.akCreationDate = data.archiveContainer?.creationDate
                  ? moment(data.archiveContainer?.creationDate).format("YYYY-MM-DD HH:mm")
                  : "";
              }

              if (data?.receipt?.lastCheckDate) {
                this.element.lastSigningDateAK = data.receipt?.lastCheckDate ? moment(data.receipt?.lastCheckDate).format("YYYY-MM-DD HH:mm") : "";
              }

              if (data?.receipt?.legalRenewalDate) {
                this.element.expirationDateAK = data.receipt?.legalRenewalDate
                  ? moment(data.receipt?.legalRenewalDate).format("YYYY-MM-DD HH:mm")
                  : "";
              }
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    exportList(): {
      title: string;
      method: () => void;
    }[] {
      return [
        ...(this.isShowTKDownload
          ? [
              {
                title: "Загрузка ТК",
                method: () => this.openDownloadTKFile(this.element?.sourceTkFsFileGuid || ""),
              },
            ]
          : []),
        ...(this.element.hduoGuid && this.isShowExport
          ? [
              {
                title: "Загрузка АК",
                method: () => this.openDownload(this.element?.hduoGuid || ""),
              },
            ]
          : []),
        ...(this.element.responseTkFileExist
          ? [
              {
                title: "Загрузка ТК с результатом обработки",
                method: () => this.getResponseDataCb(this.element?.responseTkFsFileGuid || ""),
              },
            ]
          : []),
        ...(this.element.akResponseGuid
          ? [
              {
                title: "Загрузка ТК с подтверждением размещения АК на хранение",
                method: () => this.getResponseDataCb(this.element?.akResponseGuid || ""),
              },
            ]
          : []),
      ];
    },
    resultOikElements(this: {
      oikElements: {
        isLink: boolean;
        linkRights: string[];
      }[];
      formResultLinks: (
        elements: {
          isLink: boolean;
          linkRights: string[];
        }[]
      ) => {
        isLink: boolean;
        linkRights: string[];
      }[];
    }): {
      isLink: boolean;
      linkRights: string[];
    }[] {
      return this.formResultLinks(this.oikElements);
    },
    resultAkElements(this: {
      akElements: {
        isLink: boolean;
        linkRights: string[];
      }[];
      formResultLinks: (
        elements: {
          isLink: boolean;
          linkRights: string[];
        }[]
      ) => {
        isLink: boolean;
        linkRights: string[];
      }[];
    }): {
      isLink: boolean;
      linkRights: string[];
    }[] {
      return this.formResultLinks(this.akElements);
    },
    isShowTypeLink(): boolean {
      switch (this.element?.typeCode) {
        case Type.EAD:
          return !!this.element.documentId && isAuthorityExist(this.user, authorities.EAD_LIST);
        case Type.INVENTORY:
          return !!this.element.documentId && isAuthorityExist(this.user, authorities.INVENTORY_LIST);
        case Type.INVENTORY_PROJECT:
          return !!this.element.documentId && isAuthorityExist(this.user, authorities.EXPERT_COMMISSION_LIST);
        case Type.ACT:
          return !!this.element.documentId && isAuthorityExist(this.user, authorities.CASE_ACT_LIST);
        case Type.RESPONSE_DICTIONARY_OIK:
          return !!this.element[modalFields.TK_OIK_NAME as keyof element] && isAuthorityExist(this.user, authorities.OIK_LIST);
        default:
          return false;
      }
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowTKDownload(): boolean {
      return this.element.tkFileExist || false;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, [authorities.TK_DOCUMENTS_EXPORT, authorities.TK_DICTIONARIES_EXPORT, authorities.TK_OTHER_EXPORT]);
    },
    statusColor(): string {
      switch (this.element.status) {
        case "Принят в ХД":
          return "#00A459";
        case "Ошибка":
          return "#D34039";
        default:
          return "#2462d1";
      }
    },
    formSerialNumber(): (text: string) => string {
      return (text: string) => {
        if (!text) {
          return "";
        }
        return text?.match(/.{1,2}/g)?.join(" ") || "";
      };
    },
    formDate(): (text: string) => string {
      return (text: string) => {
        if (!text) {
          return "";
        }
        return moment(text).format("YYYY-MM-DD HH:mm");
      };
    },
    modalFieldTooltip(): (field: string) => string {
      return (field: string) => {
        return this.modalElements.find((item: { value: string; tooltip?: string }) => item.value === field)?.tooltip || "";
      };
    },
    modalFieldTitle(): (field: string) => string {
      return (field: string) => {
        return this.modalElements.find((item: { value: string; tooltip?: string; title: string }) => item.value === field)?.title || "";
      };
    },
    statusResultText(): string {
      return this.element.status || "";
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    openPdf(id: string) {
      this.openNewWindow(getFullPath(`${QUERY_PATH.GET_TK_LIST_PDF}/${id}`));
    },
    openDownload(id: string) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_HDUO_DOWNLOAD)}?id=${id}`);
    },
    openDownloadTKFile(id: string) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_TK_RESPONSE_OIK)}?uuid=${id}`);
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    clickElementCb(
      this: {
        modalFields: typeof modalFields;
        element: element;
        $router: VueRouter;
      },
      element: IModalElementType
    ) {
      switch (element.value) {
        case this.modalFields.TK_OIK_NAME:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities({ isLink: element.isLink || false }, "/dictionaries/oik", {
            name: this.element[modalFields.TK_OIK_NAME as keyof element] as string,
            isOpenModal: "1",
          });
          break;
        case this.modalFields.TK_OIK_CODE:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities({ isLink: element.isLink || false }, "/dictionaries/oik", {
            code: this.element[modalFields.TK_OIK_CODE as keyof element] as string,
            isOpenModal: "1",
          });
          break;
        case this.modalFields.TK_AK_ID:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities({ isLink: element.isLink || false }, "/inventory/ak", {
            id: this.element[modalFields.TK_AK_ID as keyof element] as string,
            isOpenModal: "1",
          });
          break;
      }
    },
    async getTkElement(value: string) {
      console.log(value);
      return [];
    },
    changeOpenedId(value: string) {
      console.log(value);
      return [];
    },
    clickType(this: { element: element; $router: VueRouter; isShowTypeLink: boolean }) {
      if (!this.isShowTypeLink) {
        return;
      }
      switch (this.element?.typeCode) {
        case Type.EAD:
          this.$router.push({
            path: "/inventory/ead",
            query: { id: this.element.documentId as string, isOpenModal: "1" },
          });
          break;
        case Type.INVENTORY:
          this.$router.push({
            path: "/inventory/case-notes-list",
            query: { id: this.element.documentId as string, isOpenModal: "1" },
          });
          break;
        case Type.INVENTORY_PROJECT:
          this.$router.push({
            path: "/erc/inventory",
            query: { id: this.element.documentId as string, isOpenModal: "1" },
          });
          break;
        case Type.ACT:
          this.$router.push({
            path: "/inventory/acts",
            query: { id: this.element.documentId as string, isOpenModal: "1" },
          });
          break;
        case Type.RESPONSE_DICTIONARY_OIK:
          this.$router.push({
            path: "/dictionaries/oik",
            query: { name: this.element[modalFields.TK_OIK_NAME as keyof element] as string, isOpenModal: "1" },
          });
      }
    },
    closeModal() {
      this.$emit("close");
    },
    getResponseDataCb(guid: string) {
      let queryParams = `?uuid=${guid}&origin=true`;
      this.$store.dispatch("app/openNewWindow", getFullPath(QUERY_PATH.GET_TK_RESPONSE_OIK, queryParams), {
        root: true,
      });
    },
  },
});
