










































































































































































import { defineComponent, ref } from "@vue/composition-api";
import { currentFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import TimePicker from "@monorepo/uikit/src/components/tableViews/TimePicker.vue";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import { mapActions, mapGetters } from "vuex";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKListView/fields";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/TKListView/filtersElements";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/TKListView/viewTitle";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import FilterLabel from "@monorepo/uikit/src/components/tableViews/FilterLabel.vue";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import useAutocomplete from "@monorepo/utils/src/composables/useAutocomplete";
import { mrdSelectItems } from "@monorepo/inventory/src/views/TKListView/constants/mrdSelectItems";
import { docSelectItems } from "@monorepo/inventory/src/views/TKListView/constants/docSelectItems";

const autocompleteFiltersKeys = [fields.TK_LIST_OIK_CODE, fields.TK_LIST_OIK_NAME];

export default defineComponent({
  components: {
    TextFilter,
    DatePicker,
    TimePicker,
    SelectFilter,
    Autocomplete,
    FilterLabel,
    FindButton,
    ToggleFilters,
    ClearFilters,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sectionName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fields,
      filtersElements,
      viewUniqKey,
      defaultFilterPlaceholder,
      autocompleteFiltersKeys,
      docSelectItems,
      mrdSelectItems,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("TKListView", ["statuses", "fieldFilters", "types", "searchTemplates", "isTableLoading", "isLoadingToggleFilters"]),
    isShowFilter(): boolean {
      return isAuthorityExist(this.user, [authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST]);
    },
    tkStatus: {
      get(): string[] {
        return this.currentFilters[fields.TK_LIST_TYPE_FILTER] as string[];
      },
      set(value: string[]): void {
        this.setTKStatus(value);
      },
    },
    creationDateFrom: {
      get(): string {
        return this.currentFilters[fields.TK_LIST_CREATED_DATE_FROM] as string;
      },
      set(value: string): void {
        if (this.currentFilters[fields.TK_LIST_CREATED_DATE_TO]) {
          this.currentFilters[fields.TK_LIST_CREATED_DATE_TO] = "";
        }
        this.currentFilters[fields.TK_LIST_CREATED_DATE_FROM] = value;
      },
    },
    deliveryDateFrom: {
      get(): string {
        return this.currentFilters[fields.TK_LIST_DELIVERY_DATE_FROM] as string;
      },
      set(value: string): void {
        if (this.currentFilters[fields.TK_LIST_DELIVERY_DATE_TO]) {
          this.currentFilters[fields.TK_LIST_DELIVERY_DATE_TO] = "";
          this.currentFilters[fields.TK_LIST_DELIVERY_TIME_TO] = "";
        }
        this.currentFilters[fields.TK_LIST_DELIVERY_DATE_FROM] = value;
      },
    },
  },
  methods: {
    ...mapActions("TKListView", ["clearFilters", "addFilter", "getOikAutocompleteItems", "saveSearchTemplates"]),
    ...mapActions("TKListView", ["refreshScroll"]),
    setCurrentFilters,
    setTKStatus(value: string[]) {
      if (
        !value.length &&
        ![authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST, authorities.TK_DOCUMENTS_LIST].every((item) =>
          isAuthorityExist(this.user, item)
        )
      ) {
        this.currentFilters[fields.TK_LIST_TYPE_FILTER] = [this.types[0].value];
      } else {
        this.currentFilters[fields.TK_LIST_TYPE_FILTER] = value;
      }
    },
    clearFiltersCustomCb(this: {
      clearFilters: () => void;
      restoreFiltersFromStore: (is: boolean) => void;
      refreshScroll: () => void;
      filtersDivHeight: number;
      defaultFiltersDivHeight: number;
      currentFilters: Record<string, unknown>;
      addFilter: (currentFilters: Record<string, unknown>) => void;
      setTKStatus: (value: string[]) => void;
    }) {
      this.clearFilters();
      this.filtersDivHeight = this.defaultFiltersDivHeight;
      this.restoreFiltersFromStore(true);
      this.setTKStatus([]);
      this.addFilter(this.currentFilters);
      this.refreshScroll();
    },
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref((setCurrentFilters as () => Record<string, unknown>)());
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    useTemplateFilters(root, props.sectionName, currentFilters);
    const {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      restoreFiltersFromStore,
    } = useFilter(
      context,
      props as {
        value: boolean;
      },
      props.sectionName,
      {},
      currentFilters,
      setCurrentFilters,
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );
    const { getAutocompleteItems } = useAutocomplete(
      root,
      props.sectionName,
      currentFilters,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
      autocompleteFiltersKeys as string[]
    );

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      restoreFiltersFromStore,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      getAutocompleteItems,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
