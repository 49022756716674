import { convertAutocompleteItemToApi, convertOikCodeFilter, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKListView/fields";
import { convertStatus } from "@monorepo/utils/src/api/convertDeleteStatus";
import { mrdSelectItems } from "@monorepo/inventory/src/views/TKListView/constants/mrdSelectItems";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      creationDateFrom?: string;
      creationDateTo?: string;
      status?: string;
      dateFrom?: string;
      dateTo?: string;
      timeFrom?: string;
      timeTo?: string;
    };
  }
): void => {
  formDateFilter(resultFilters, "creationDate");
  formDateFilter(resultFilters, "date");
  formDateFilter(resultFilters, "time");
  convertOikCodeFilter(resultFilters);
  convertStatus(resultFilters, "tkContainsMrd", "isMrd", mrdSelectItems);
  convertAutocompleteItemToApi(resultFilters, fields.TK_LIST_OIK_CODE, `fieldFilters.${fields.TK_LIST_OIK_CODE}.code`);
  convertAutocompleteItemToApi(resultFilters, fields.TK_LIST_OIK_NAME, `fieldFilters.${fields.TK_LIST_OIK_NAME}.name`);
};
