export interface ITKTableFields {
  TK_LIST_ID: string;
  TK_LIST_TYPE: string;
  TK_LIST_TYPE_FILTER: string;
  TK_LIST_CREATED_DATE: string;
  TK_LIST_CREATED_DATE_FROM: string;
  TK_LIST_CREATED_DATE_TO: string;
  TK_LIST_DELIVERY_DATE: string;
  TK_LIST_DELIVERY_DATE_FROM: string;
  TK_LIST_DELIVERY_DATE_TO: string;
  TK_LIST_DELIVERY_TIME_FROM: string;
  TK_LIST_DELIVERY_TIME_TO: string;
  TK_LIST_OIK_ID: string;
  TK_LIST_OIK_CODE: string;
  TK_LIST_OIK_NAME: string;
  TK_LIST_STATUS: string;
  TK_LIST_GUID: string;
  TK_LIST_MEDO_GUID: string;
  TK_SOURCE_TYPE: string;
  TK_HAS_MRD: string;
  FILTER_HAS_MRD: string;
}

export const fields: ITKTableFields = {
  TK_LIST_ID: "id",
  TK_LIST_TYPE: "type",
  TK_LIST_TYPE_FILTER: "tkType",
  TK_LIST_CREATED_DATE: "creationDate",
  TK_LIST_CREATED_DATE_FROM: "creationDateFrom",
  TK_LIST_CREATED_DATE_TO: "creationDateTo",
  TK_LIST_DELIVERY_DATE: "receivedAt",
  TK_LIST_DELIVERY_DATE_FROM: "dateFrom",
  TK_LIST_DELIVERY_DATE_TO: "dateTo",
  TK_LIST_DELIVERY_TIME_FROM: "timeFrom",
  TK_LIST_DELIVERY_TIME_TO: "timeTo",
  TK_LIST_OIK_ID: "tkOikGuid",
  TK_LIST_OIK_CODE: "oikCode",
  TK_LIST_OIK_NAME: "oikName",
  TK_LIST_STATUS: "status",
  TK_LIST_GUID: "guid",
  TK_LIST_MEDO_GUID: "medoGuid",
  TK_SOURCE_TYPE: "sourceType",
  TK_HAS_MRD: "tkContainsMrd",
  FILTER_HAS_MRD: "isMrd",
};
