







import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SelectedColumn",
  props: {
    width: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
  },
});
