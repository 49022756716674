import { fields } from "@monorepo/utils/src/variables/projectsData/TKListView/fields";
import { v4 as uuid } from "uuid";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Дата создания ТК в ОИК",
    tooltip: "Дата создания ТК в ОИК",
    defaultWidth: 181,
    value: fields.TK_LIST_CREATED_DATE,
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
  {
    text: "Дата поступления ТК в ЦХЭД",
    tooltip: "Дата поступления ТК в ЦХЭД",
    defaultWidth: 212,
    value: fields.TK_LIST_DELIVERY_DATE,
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
  {
    text: "Идентификатор ТК ОИК",
    tooltip: "Идентификатор ТК в ИС ОИК",
    defaultWidth: 200,
    value: fields.TK_LIST_OIK_ID,
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
  {
    text: "Идентификатор ТК в ЦХЭД",
    tooltip: "Идентификатор ТК в ЦХЭД",
    defaultWidth: 200,
    value: fields.TK_LIST_ID,
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
    isHiddenByDefault: true,
  },
  {
    text: "Статус ТК",
    defaultWidth: 130,
    tooltip: "Статус ТК может принимать значения: В обработке, Передача в ХД, Ошибка, Принят в ХД",
    value: fields.TK_LIST_STATUS,
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
  {
    text: "Код ОИК",
    defaultWidth: 146,
    tooltip: "Кодовое обозначение ОИК",
    value: fields.TK_LIST_OIK_CODE,
    isSorted: true,
    isHandle: true,
    isLink: true,
    linkRights: [authorities.OIK_LIST],
    id: uuid(),
  },
  {
    text: "Название ОИК",
    tooltip: "Название ОИК",
    defaultWidth: 160,
    value: fields.TK_LIST_OIK_NAME,
    isSorted: true,
    isHandle: true,
    isLink: true,
    linkRights: [authorities.OIK_LIST],
    id: uuid(),
  },
  {
    text: "Тип ТК",
    defaultWidth: 160,
    tooltip: "Тип ТК может принимать значения:  Номенклатура, Опись, Документ, Акт, Сообщение",
    value: fields.TK_LIST_TYPE,
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
  {
    text: "Канал поступления",
    defaultWidth: 160,
    tooltip: "Канал поступления:  МЭДО, API",
    value: fields.TK_SOURCE_TYPE,
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
  {
    text: "Наличие МЧД",
    defaultWidth: 160,
    tooltip: "Наличие МЧД:  да, нет",
    value: fields.TK_HAS_MRD,
    isSorted: true,
    isHandle: true,
    isLink: false,
    id: uuid(),
  },
];
