












































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/inventory/src/views/TKListView/components/Filters.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import SelectedColumn from "@monorepo/inventory/src/views/TKListView/components/SelectedColumn.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ToggleTableCellsContainer from "@monorepo/inventory/src/views/TKListView/components/ToggleTableCellsContainer.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/TKListView/tableHeaders";
import { defineComponent, ref } from "@vue/composition-api";
import { module as TKListView } from "@monorepo/inventory/src/store/modules/TKListView";
import useInitCheckStatusesLib from "@monorepo/inventory/src/views/TKListView/composables/useInitCheckStatusesLib";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/inventory/src/views/TKListView/constants/breadcrumbs";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import TKListInfoModal from "@monorepo/inventory/src/views/TKListView/components/TKListInfoModal.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKListView/fields";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/TKListView/viewTitle";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/TKListView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "TKListView";

export interface IData {
  breadcrumbs: typeof breadcrumbs;
  viewTitle: string;
  selection: null | { left: number; width: number };
  viewUniqKey: string;
  pluralFormTitles: string[];
  tableHeaders: typeof tableHeaders;
  convertApiItemToUi: typeof convertApiItemToUi;
  fields: typeof fields;
}

export default defineComponent({
  name: "TKListView",
  components: {
    TableSettings,
    ExportButton,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    SelectedColumn,
    TKListInfoModal,
    ToggleTableCellsContainer,
    AutoRefreshButton,
    Footer,
    ExportFooterButton,
  },
  data(): IData {
    return {
      breadcrumbs,
      viewTitle,
      tableHeaders,
      selection: null,
      viewUniqKey,
      pluralFormTitles: ["ТК"],
      convertApiItemToUi,
      fields,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "isSelectAll",
      "infiniteId",
      "data",
      "isOpenFilters",
      "openedId",
      "selectedIds",
      "totalLength",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
    ]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, [authorities.TK_DOCUMENTS_EXPORT, authorities.TK_DICTIONARIES_EXPORT, authorities.TK_OTHER_EXPORT]);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && this.isShowExport;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["addOpenedId", "getEventList", "getExportData", "setIsOpenFilters", "setAutorefresh"]),
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        this.addOpenedId(this.data[0].id);
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          this.$store.dispatch("TKListView/addOpenedId", event[fields.TK_LIST_ID]);
          this.toggleModal(true);
          break;
        case fields.TK_LIST_OIK_CODE:
          this.moveByAuthorities(header, "/dictionaries/oik", {
            code: event[fields.TK_LIST_OIK_CODE] as string,
            id: (event.oik as { id: string }).id,
            isOpenModal: "1",
          });
          break;
        case fields.TK_LIST_OIK_NAME:
          this.moveByAuthorities(header, "/dictionaries/oik", {
            name: event[fields.TK_LIST_OIK_NAME] as string,
            id: (event.oik as { id: string }).id,
            isOpenModal: "1",
          });
          break;
        default:
          break;
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const moveByAuthorities = useMoveByAuthorities(root);

    useInitTableStoreModule(root, SECTION_NAME, TKListView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders, true).then(() => {
      useRefreshTable(root, SECTION_NAME);
    });
    useInitCheckStatusesLib(root)
      .then(() => {
        if (
          ![authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST, authorities.TK_DOCUMENTS_LIST].every((item) =>
            isAuthorityExist(root.$store.state.auth.user, item)
          )
        ) {
          const value = root.$store.getters[`${SECTION_NAME}/types`]?.[0]?.value;
          root.$store.dispatch(`${SECTION_NAME}/addFilter`, { [fields.TK_LIST_TYPE_FILTER]: value ? [value] : [] });
        }
        return useSetStartFilters(root, SECTION_NAME, ["id", "guid", "medoGuid", "tkOikGuid"]);
      })
      .then(() => {
        useInitEducationPanel(root);
        filtersLoaded.value = true;
        useSetStartModal(root, SECTION_NAME);
      });
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
      moveByAuthorities,
    };
  },
});
