export enum Type {
  INVENTORY = "INVENTORY",
  EAD = "EAD",
  INVENTORY_PROJECT = "INVENTORY_PROJECT",
  RESPONSE_OIK = "RESPONSE_OIK",
  DICTIONARY_OIK = "DICTIONARY_OIK",
  RESPONSE_DICTIONARY_OIK = "RESPONSE_DICTIONARY_OIK",
  MEDO_RECEIPT = "MEDO_RECEIPT",
  OTHER = "OTHER",
  ACT = "ACT",
  NOMENCLATURE = "NOMENCLATURE",
}
