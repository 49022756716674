import { v4 as uuid } from "uuid";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const modalFields = {
  TK_ID: "id",
  TK_ID_OIK: "tkOikGuid",
  TK_RECEIVED_AT: "receivedAt",
  TK_TYPE: "typeName",
  TK_VIEW: "view",
  TK_STATUS: "statusMessage",
  TK_VOLUME: "volume",
  TK_OIK_CODE: "oikCode",
  TK_OIK_NAME: "oikName",
  TK_SW_NAME: "swName",
  TK_SW_VERSION: "swVersion",
  TK_DIGITAL_SIGNATURE: "digitalSignature",
  TK_COMMON_NAME: "commonName",
  TK_POSITION: "position",
  TK_ORGANIZATION_NAME: "organizationName",
  TK_SOURCE_TYPE: "sourceType",

  TK_EP_SER_NUM: "serNum",
  TK_EP_EXPIRED_AT: "expiredAt",
  TK_EP_SIGN_TIME: "signTime",
  TK_EP_TIME_STAMP: "timeStamp",
  TK_EP_TS_SER_NUM: "tsSerNum",

  TK_TRANSPORT_ID: "medoGuid",
  TK_TRANSPORT_DATE: "transportDate",
  TK_TRANSPORT_SOURCE_ID: "transportSourceGuid",
  TK_TRANSPORT_DESTINATION_ID: "transportDestinationGuid",
  TK_CREATION_DATE_AK: "akCreationDate",
  TK_LAST_SIGNING_DATE_AK: "lastSigningDateAK",
  TK_EXPIRATION_DATE_AK: "expirationDateAK",
  TK_AK_HDUO_ID: "hduoId",
  TK_STATUS_AK: "akStatus",
  TK_AK_ID: "akId",
};

export const baseModalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор ТК ЦХЭД",
    tooltip: "Идентификатор ТК ЦХЭД",
    className: "info-modal__element_title",
    value: modalFields.TK_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор ТК ОИК",
    tooltip: "Идентификатор ТК ОИК",
    className: "info-modal__element_title",
    value: modalFields.TK_ID_OIK,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата поступления ТК в ЦХЭД",
    tooltip: "Дата и время поступления в ЦХЭД",
    className: "info-modal__element_title",
    value: modalFields.TK_RECEIVED_AT,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип",
    tooltip: "Тип ТК может принимать значения:  Номенклатура, Опись, Документ, Акт, Сообщение",
    className: "info-modal__element_title",
    value: modalFields.TK_TYPE,
    slotName: "typeLink",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус",
    tooltip: "Статус ТК может принимать значения: В обработке, Передача в ХД, Ошибка, Принят в ХД",
    className: "info-modal__element",
    value: modalFields.TK_STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Объем",
    tooltip: "Размер ТК в мегабайтах",
    className: "info-modal__element_size",
    value: modalFields.TK_VOLUME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Канал поступления",
    tooltip: "Канал поступления",
    className: "info-modal__element_size",
    value: modalFields.TK_SOURCE_TYPE,
  },
];

export const oikElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Код",
    tooltip: "Код ОИК",
    className: "info-modal__element",
    value: modalFields.TK_OIK_CODE,
    isLink: true,
    linkRights: [authorities.OIK_LIST],
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Название",
    tooltip: "Название ОИК",
    className: "info-modal__element",
    isLink: true,
    linkRights: [authorities.OIK_LIST],
    value: modalFields.TK_OIK_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Название ПО",
    tooltip: "Название ПО, в котором был сформирован ТК",
    className: "info-modal__element",
    value: modalFields.TK_SW_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Версия ПО",
    tooltip: "Версия ПО,  в котором был сформирован ТК",
    className: "info-modal__element_size",
    value: modalFields.TK_SW_VERSION,
  },
];

export const medoElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    tooltip: "Идентификатор ТК в МЭДО",
    className: "info-modal__element_title",
    value: modalFields.TK_TRANSPORT_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата регистрации",
    tooltip: "Время создания ТК в транспортной системе",
    className: "info-modal__element_title",
    value: modalFields.TK_TRANSPORT_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор источника",
    tooltip: "Идентификатор источника в транспортной системе",
    className: "info-modal__element_title",
    value: modalFields.TK_TRANSPORT_SOURCE_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор получателя",
    tooltip: "Идентификатор получателя в транспортной системе",
    className: "info-modal__element_title",
    value: modalFields.TK_TRANSPORT_DESTINATION_ID,
  },
];

export const akElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор АК",
    tooltip: "Идентификатор АК",
    className: "info-modal__element_title",
    isLink: true,
    linkRights: [authorities.AK_LIST],
    value: modalFields.TK_AK_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Имя файла АК в хранилище данных",
    tooltip: "Имя файла АК в хранилище данных",
    className: "info-modal__element_title",
    value: modalFields.TK_AK_HDUO_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус",
    tooltip: "Статус АК может принимать значения: Сформирован, Размещён на хранение, Требует переподписания",
    className: "info-modal__element_title",
    value: modalFields.TK_STATUS_AK,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата создания",
    tooltip: "Дата создания АК и квитанции",
    className: "info-modal__element_title",
    value: modalFields.TK_CREATION_DATE_AK,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата последней проверки",
    tooltip: "Дата последней проверки АК и обновления квитанции",
    className: "info-modal__element_title",
    value: modalFields.TK_LAST_SIGNING_DATE_AK,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата продления юридической значимости",
    tooltip: "Дата, до которой необходимо провести процедуру продления юридической значимости",
    className: "info-modal__element_title",
    value: modalFields.TK_EXPIRATION_DATE_AK,
  },
];

export const signatureElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Кому выдан",
    tooltip: "Фамилия имя и отчество владельца сертификата",
    className: "info-modal__element_title",
    value: modalFields.TK_COMMON_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Наименование юридического лица АУЦ, выдавшего сертификат	",
    title: "Кем выдан",
    className: "info-modal__element_title",
    value: modalFields.TK_ORGANIZATION_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Серийный номер сертификата",
    title: "Серийный номер",
    className: "info-modal__element_title",
    value: modalFields.TK_EP_SER_NUM,
  },
  // {
  //   id: uuid(),
  //   isEdited: false,
  //   title: "Электронная подпись ТК",
  //   className: "info-modal__element",
  //   value: modalFields.TK_DIGITAL_SIGNATURE,
  // },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Дата окончания действия сертификата",
    title: "Действителен",
    className: "info-modal__element",
    value: modalFields.TK_EP_EXPIRED_AT,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Дата подписания файла электронной подписью",
    title: "Дата подписания",
    className: "info-modal__element",
    value: modalFields.TK_EP_SIGN_TIME,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Штамп времени",
    title: "Штамп времени",
    className: "info-modal__element_title",
    value: modalFields.TK_EP_TIME_STAMP,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Серийный номер сервера штампа времени",
    title: "Серийный номер сервера штампа времени",
    className: "info-modal__element_title",
    value: modalFields.TK_EP_TS_SER_NUM,
  },
];

export const mrpElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Номер доверенности",
    tooltip: "Номер доверенности",
    className: "info-modal__element_title",
    value: "mrdPowerAttorneyNumber",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата доверенности",
    tooltip: "Дата доверенности",
    className: "info-modal__element",
    value: "mrdCreationDate",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Срок действия",
    tooltip: "Срок действия",
    className: "info-modal__element",
    value: "mrdExpiredAt",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование",
    tooltip: "Наименование доверителя",
    className: "info-modal__element_title",
    value: "mrdTrusteeCompanyName",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ИНН",
    tooltip: "ИНН доверителя",
    className: "info-modal__element",
    value: "mrdTrusteeInn",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОГРН",
    tooltip: "ОГРН доверителя",
    className: "info-modal__element",
    value: "mrdTrusteeOgrn",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО лица, действующего без доверенности",
    tooltip: "ФИО лица, действующего без доверенности",
    className: "info-modal__element_title",
    value: "mrdTrusteeFio",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО представителя",
    tooltip: "ФИО представителя",
    className: "info-modal__element_title",
    value: "mrdRepresentativeFio",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Полномочия представителя по доверенности",
    tooltip: "Полномочия представителя по доверенности",
    className: "info-modal__element_title",
    value: "mrdPermissions",
    isShowContentTooltip: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус МЧД",
    tooltip: "Статус МЧД на дату поступления МЧД в ЦХЭД",
    className: "info-modal__element_title",
    value: "mrdStatus",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус проверки ЭП МЧД",
    tooltip: "Статус проверки ЭП МЧД",
    className: "info-modal__element_title",
    value: "mrdEpStatus",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус проверки метаданных МЧД",
    tooltip: "Статус проверки метаданных МЧД",
    className: "info-modal__element_title",
    value: "mrdMetaStatus",
    isShowContentTooltip: true,
  },
];

export const fullModalElements = [...baseModalElements, ...oikElements, ...medoElements, ...akElements, ...signatureElements, ...mrpElements];
