import { fields } from "@monorepo/utils/src/variables/projectsData/TKListView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.TK_LIST_TYPE_FILTER]: [],
  [fields.TK_LIST_STATUS]: [],
  [fields.TK_LIST_OIK_ID]: "",
  [fields.TK_LIST_GUID]: "",
  [fields.TK_SOURCE_TYPE]: "",
  [fields.TK_LIST_ID]: "",
  [fields.TK_LIST_MEDO_GUID]: "",
  [fields.TK_LIST_OIK_CODE]: {},
  [fields.TK_LIST_OIK_NAME]: {},
  [fields.TK_LIST_CREATED_DATE_FROM]: "",
  [fields.TK_LIST_CREATED_DATE_TO]: "",
  [fields.TK_LIST_DELIVERY_DATE_FROM]: "",
  [fields.TK_LIST_DELIVERY_DATE_TO]: "",
  [fields.TK_LIST_DELIVERY_TIME_FROM]: "",
  [fields.TK_LIST_DELIVERY_TIME_TO]: "",
  [fields.FILTER_HAS_MRD]: [],
});
