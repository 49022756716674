export const docSelectItems = [
  {
    value: "MEDO",
    title: "МЭДО",
  },
  {
    value: "API",
    title: "API",
  },
];
